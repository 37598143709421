<template>
  <div>
    <div class="mt-4 mb-3">
      <template>
        <v-card>
          <template>
            <v-data-table :headers="headers" :items="relatorio" :single-expand="singleExpand" :expanded.sync="expanded"
              :hide-default-footer="true" item-key="produto" show-expand class="elevation-1">
              <template v-slot:[`item.valor_total`]="{ item }">
                {{ toReal(item.valor_total) || toReal(0) }}
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="relatorio">
                  <div class="mt-4 mb-5">
                    <v-data-table :headers="headersDetalhamento" :items="item.eixos"
                      :hide-default-footer="true" class="elevation-1">
                      <template v-slot:[`item.valor_total`]="{ item }">
                        {{ toReal(item.valor_total) || toReal(0) }}
                      </template>
                    </v-data-table>
                  </div>
                </td>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </template>
    </div>

    <div class="mt-10">
      <v-data-table :headers="headerstotal" :items="relatorioTotalTable" hide-default-footer class="elevation-1">
        <template v-slot:[`item.valor_total`]="{ item }">
          {{ toReal(item.valor_total) || toReal(0) }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { toReal } from '@/mixins/convertion.js'

export default {
  name: 'RelatorioProdutoEixo',

  mixins: [toReal],

  data() {
    return {
      expanded: [],
      singleExpand: false,
      faturamento: [],
      relatorio: [],
      search: '',
      numeroTotalCaminhoes: 0,
      totalServicos: 0,
      dialog: null,
      headers: [
        {
          text: 'Produto',
          align: 'start',
          value: 'produto',
        },
        { text: 'Número Total de Caminhões', value: 'quantidade_caminhoes' },
        { text: 'Valor Total do Serviço', value: 'valor_total' },
      ],
      headersDetalhamento: [
        { text: 'Eixo', value: 'eixo' },
        { text: 'Número de Caminhões', value: 'quantidade_caminhoes' },
        { text: 'Valor', value: 'valor_total' },
      ],
      headerstotal: [
        { text: 'Total de Caminhões', value: 'total_caminhoes' },
        { text: 'Total Arrecadado', value: 'valor_total' },
      ],
      valores: {},
      relatorioTotal: [],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('relatorioServico', ['params']),

    relatorioTotalTable() {
      return this.relatorioTotalMounted()
    },
  },
  methods: {
    ...mapActions('patio', [
      'getRelatorioProdutoEixo',
    ]),
    ...mapActions('template', ['errorMessage']),

    async getServico() {
      if (!Object.keys(this.params).length) return

      try {
        const relatorio = await this.getRelatorioProdutoEixo({
          ...this.params,
        })
        this.relatorio = relatorio
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },

    totalCaminhoes() {
      this.numeroTotalCaminhoes = this.relatorio.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.quantidade_caminhoes
        },
        0
      )
      return this.numeroTotalCaminhoes
    },

    valorTotalServicos() {
      this.totalServicos = this.relatorio.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.valor_total
        },
        0
      )
      return this.totalServicos
    },

    relatorioTotalMounted() {
      this.valores.total_caminhoes = this.totalCaminhoes()
      this.valores.valor_total = this.valorTotalServicos()
      this.relatorioTotal.splice(0, 1, this.valores)

      return this.relatorioTotal
    },
  },

  async created() { },
}
</script>

<style scoped>
.relatorio {
  background-color: rgb(204, 206, 205);
}
</style>
