export const data = () => ({
  optionsSelected: [],
  options: [
    {
      id: 10,
      name: 'Data',
      children: [
        {
          id: 'data_inicio',
          name: 'De',
        },
        {
          id: 'data_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 20,
      name: 'Fornecedor',
      children: [
        {
          id: 'fornecedor_field',
          name: 'Fornecedor',
        },
        {
          id: 'produto',
          name: 'Produto',
        },
        {
          id: 'sub_produto',
          name: 'Sub Produto',
        },
      ],
    },
    {
      id: 30,
      name: 'Contratos',
      children: [
        {
          id: 'servico',
          name: 'Serviço',
        },
        {
          id: 'status',
          name: 'Status serviço',
        },
        {
          id: 'status_agendamento',
          name: 'Status Agendamento',
        },
        {
          id: 'terminal',
          name: 'Terminal',
        },
        {
          id: 'armazem',
          name: 'Armazem',
        },
        {
          id: 'cliente',
          name: 'Cliente',
        },
        {
          id: 'transportadora',
          name: 'Transportadora',
        },
        {
          id: 'colaborador',
          name: 'Colaborador',
        },
      ],
    },
    {
      id: 40,
      name: 'Região',
      children: [
        {
          id: 'regiao',
          name: 'Região',
        },
        {
          id: 'representante',
          name: 'Representante',
        },
      ],
    },
    {
      id: 50,
      name: 'Veículo',
      children: [
        {
          id: 'motorista',
          name: 'Motorista',
        },
        {
          id: 'placa_cavalo',
          name: 'Placa',
        },
        {
          id: 'eixo',
          name: 'Eixo',
        },
      ],
    },
    {
      id: 60,
      name: 'Forma de pagamento',
      children: [
        {
          id: 'forma_pgto',
          name: 'Forma de pagamento',
        },
      ],
    },

  ],
})
