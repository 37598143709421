import { mapGetters, mapState } from 'vuex'

import { LOCAL_STORAGE_ID } from "@/utils/relatorioServico"

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState('fornecedores', ['fornecedores']),
  ...mapState('clientes', ['clientes']),
  ...mapState('transportadoras', ['transportadoras']),
  ...mapGetters('contratos', ['terminais']),
  ...mapState('patio', ['regioes', 'representantes']),
  ...mapState('motoristas', ['motoristas']),
  ...mapState('gerencial', ['colaboradores']),

  showData() {
    return this.params.data_inicio.visible || this.params.data_fim.visible
  },
  showFornecedor() {
    return this.params.fornecedor_field.visible || this.params.produto.visible || this.params.sub_produto.visible
  },
  showContratos() {
    return (this.params.colaborador?.visible ||
      this.params.servico?.visible ||
      this.params.status?.visible ||
      this.params.status_agendamento?.visible ||
      this.params.terminal?.visible ||
      this.params.cliente?.visible ||
      this.params.transportadora?.visible)
  },
  showRegiao() {
    return this.params.regiao.visible || this.params.representante.visible
  },
  showFormaPgto() {
    return this.params.forma_pgto?.visible
  },
  showVeiculo() {
    return this.params.motorista.visible || this.params.placa_cavalo.visible || this.params.eixo.visible
  },
  areSettingsEmpty() {
    return !(this.showData || this.showFornecedor || this.showContratos || this.showRegiao || this.showVeiculo || this.showFormaPgto)
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  },
  selectedFornecedor() {
    return this.params.fornecedor_field.value
  },
  selectedProduto() {
    return this.params.produto.value
  }
}
