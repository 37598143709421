export function data() {
  return {
    settingFilters: false,
    dependents: {
      fornecedor_field: ["produto", "sub_produto"],
      terminal: ["armazem"],
    },
    gates: [],
    params: {
      data_inicio: {
        value: '',
        visible: false,
      },
      data_fim: {
        value: '',
        visible: false,
      },
      gate: {
        value: '',
        visible: false,
        // get: () => { },
      },
      proprietario: {
        value: '',
        visible: false,
        // get: () => { },
      },
      fornecedor_field: {
        value: '',
        visible: false,
        //get: this.getFornecedores,
      },
      produto: {
        value: '',
        visible: false,
      },
      sub_produto: {
        value: '',
        visible: false,
      },
      cliente: {
        value: '',
        visible: false,
        //get: this.getClientes,
      },
      colaborador: {
        value: '',
        visible: false,
      },
      transportadora: {
        value: '',
        visible: false,
        //get: this.getTransportadoras,
      },
      terminal: {
        value: '',
        visible: false,
        get: this.getContratos,
      },
      armazem: {
        value: '',
        visible: false,
      },
      regiao: {
        value: '',
        visible: false,
        get: this.getRegioes,
      },
      representante: {
        value: '',
        visible: false,
        get: this.getRepresentantes,
      },
      motorista: {
        value: '',
        visible: false,
        get: this.getMotoristas,
      },
      placa_cavalo: {
        value: '',
        visible: false,
      },
      eixo: {
        value: '',
        visible: false,
      },
      servico: {
        value: null,
        visible: false,
      },
      status: {
        value: false,
        visible: false,
      },
      status_agendamento: {
        value: false,
        visible: false,
      },
      forma_pgto: {
        value: null,
        visible: false,
      },
    },
    allOptions: [
      'data_inicio',
      'data_fim',
      'fornecedor_field',
      'produto',
      'sub_produto',
      'terminal',
      'armazem',
      'cliente',
      'colaborador',
      'transportadora',
      'regiao',
      'representante',
      'motorista',
      'placa_cavalo',
      'servico',
      'status',
      'status_agendamento',
      'eixo',
      'forma_pgto'
    ],
  }
}
