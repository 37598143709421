<template>
  <div>
    <div class="mt-4 mb-8">
      <template>
        <v-card>
          <!--<v-card-title>
            Total
            <v-spacer></v-spacer>
          </v-card-title>-->

          <template>
            <v-data-table
              :headers="headersTotal"
              :items="totalServicos"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              :hide-default-footer="true"
              :search="search"
              item-key="total_caminhoes"
              show-expand
              class="elevation-1"
            >
              <template v-slot:[`item.valor_total`]="{ item }">
                {{ toReal(item.valor_total) || toReal(0) }}
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="relatorio">
                  <div class="mt-4 mb-5">
                    <v-data-table
                      :headers="headersTotalDetalhamento"
                      :items="item.formas_de_pagamento"
                      :hide-default-footer="true"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.valor_total`]="{ item }">
                        {{ toReal(item.valor_total) || toReal(0) }}
                      </template>
                    </v-data-table>
                  </div>
                </td>
              </template>
            </v-data-table>
          </template>

          <v-spacer></v-spacer>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { toReal } from '@/mixins/convertion.js'

export default {
  name: 'RelatorioTotal',

  mixins: [toReal],

  data() {
    return {
      expanded: [],
      singleExpand: false,
      faturamento: [],
      totalServicos: [],
      total: 0,
      search: '',
      dialog: null,
      headers: [
        {
          text: 'Serviço',
          align: 'start',
          value: 'servico',
        },
        { text: 'Número Total de Caminhões', value: 'quantidade_caminhoes' },
        { text: 'Valor Total do Serviço', value: 'valor_total' },
      ],
      headersDetalhamento: [
        { text: 'Forma de Pagamento', value: 'metodo_pagamento' },
        { text: 'Número de Caminhões', value: 'quantidade_caminhoes' },
        { text: 'Valor', value: 'valor_total' },
      ],
      headersTotal: [
        { text: 'Total de Caminhões', value: 'total_caminhoes' },
        { text: 'Total Arrecadado', value: 'valor_total' },
      ],
      headersTotalDetalhamento: [
        { text: 'Forma de Pagamento', value: 'metodo_pagamento' },
        { text: 'Número de Caminhões', value: 'quantidade_caminhoes' },
        { text: 'Valor', value: 'valor_total' },
      ],
      relatorioTotalTeste: [
        {
          total_caminhoes: 3055,
          valor_total: 102850,
          formas_de_pagamento: [
            {
              metodo_pagamento: 'DINHEIRO',
              quantidade_caminhoes: 2898,
              valor_total: 97725,
            },
            {
              metodo_pagamento: 'PIX',
              quantidade_caminhoes: 157,
              valor_total: 5125,
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('relatorioServico', ['params']),
  },
  methods: {
    ...mapActions('patio', [
      'getAllAgendamentosByParams',
      'getRelatorioServico',
      'getRegioesColaborator',
      'getRelatorioServicoTotal',
    ]),
    ...mapActions('template', ['errorMessage']),

    async getServico() {
      if (!Object.keys(this.params).length) return

      if (
        this.empresaAtual.representantes.length > 0 &&
        this.params.regiao == undefined
      ) {
        await this.getRegioesColaborator()
        if (this.regioes.length > 0) {
          this.params.regiao = this.regioes[0].sigla
        }
      }

      try {
        const total = await this.getRelatorioServicoTotal({
          ...this.params,
        })
        this.totalServicos.splice(0, 1, total)
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },
  },

  async created() {},
}
</script>

<style scoped>
.relatorio {
  background-color: rgb(204, 206, 205);
}
</style>
