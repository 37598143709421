<template>
  <div>
    <div class="mt-4">
      <v-card-title>
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        sort-by="servico,data"
        :items="faturamento"
        :footer-props="{
          itemsPerPageAllText: `Todos (${faturamento.length})`,
          itemsPerPageOptions: [50, 100, 150, 200, -1],
        }"
      >
        <template v-slot:[`item.valor`]="{ item }">
          {{ toReal(item.valor) || toReal(0) }}
        </template>
      </v-data-table>
    </div>

    <div>
      <v-data-table
        :headers="headerstotal"
        :items="relatorioTotalTable"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:[`item.valor_total`]="{ item }">
          {{ toReal(item.valor_total) || toReal(0) }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { toReal } from '@/mixins/convertion.js'

export default {
  name: 'ListagemRelatorioServicoEixo',

  mixins: [toReal],

  data() {
    return {
      faturamento: [],
      search: '',
      dialog: null,
      totalServicos: 0,
      totalCaminhoes: 0,
      relatorioTotal: [],
      headers: [
        { text: 'Sub Produto', value: 'subProduto' },
        { text: 'Eixo', value: 'eixo' },
        { text: 'Quantidade', value: 'count' },
        { text: 'Valor', value: 'valor' },
      ],
      headerstotal: [
        { text: 'Total de Caminhões', value: 'total_caminhoes' },
        { text: 'Total Arrecadado', value: 'valor_total' },
      ],
      valoresExemplo: [
        {
          total_caminhoes: 100,
          valor_total: 2000,
        },
      ],
      valores: {
        total_caminhoes: null,
        valor_total: null,
      },
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('relatorioServico', ['params']),

    relatorioTotalTable() {
      return this.relatorioTotalMounted()
    },
  },
  methods: {
    ...mapActions('patio', [
      'getAllAgendamentosByParams',
      'getRelatorioServicoEixo',
      'getRegioesColaborator',
    ]),
    ...mapActions('template', ['errorMessage']),

    async getServico(page = 1, page_size = 200) {
      if (!Object.keys(this.params).length) return

      if (
        this.empresaAtual.representantes.length > 0 &&
        this.params.regiao == undefined
      ) {
        await this.getRegioesColaborator()
        if (this.regioes.length > 0) {
          this.params.regiao = this.regioes[0].sigla
        }
      }

      try {
        const results = await this.getRelatorioServicoEixo({
          page,
          page_size: (page_size === -1 && this.dataTotal) || page_size,
          ...this.params,
        })
        this.faturamento = results
        this.quantidadeTotal()
        this.valorTotal()
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },
    quantidadeTotal() {
      this.totalCaminhoes = this.faturamento.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.count
        },
        0
      )
    },
    valorTotal() {
      this.totalServicos = this.faturamento.reduce(
        (accumulator, currentValue) => {
          return accumulator + currentValue.valor
        },
        0
      )
    },
    relatorioTotalMounted() {
      this.valores.total_caminhoes = this.totalCaminhoes
      this.valores.valor_total = this.totalServicos
      this.relatorioTotal.splice(0, 1, this.valores)

      return this.relatorioTotal
    },
  },

  async created() {},
}
</script>
