<template>
  <v-col>
    <v-autocomplete
      v-model="model"
      :label="label"
      :items="formasPagamentoNotConvenio"
      item-text="descricao"
      return-object
      multiple
    ></v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ServicoListFiltroModalItemInputFormaPagamento',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters('formaPagamento', ['formasPagamentoNotConvenio']),
    model: {
      get() {
        console.log(this.value)
        return this.value
      },
      set(value) {
        console.log(this.value)
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...mapActions('formaPagamento', ['getFormasPagamento'])
  },
  async created() {
    try {
      await this.getFormasPagamento()
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
</script>
