var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-4 mb-3"},[[_c('v-card',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.relatorio,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"hide-default-footer":true,"item-key":"servico","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.valor_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor_total) || _vm.toReal(0))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"relatorio",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"mt-4 mb-5"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersDetalhamento,"items":item.formas_de_pagamento,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.valor_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor_total) || _vm.toReal(0))+" ")]}}],null,true)})],1)])]}}],null,true)})]],2)]],2),_c('div',{staticClass:"mt-10"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerstotal,"items":_vm.relatorioTotalTable,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.valor_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor_total) || _vm.toReal(0))+" ")]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }