<template>
  <div>
    <v-col>
    <v-autocomplete
      v-model="model"
      label="Serviços"
      multiple
      :items="servicos"
      item-text="descricao"
      return-object
    ></v-autocomplete>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ContratoAPI from '@/services/tenants/contratos'
export default {
  name: 'ServicoListFiltroModalItemServicos',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('patio', ['regioes', 'servicos']),
    ...mapState('auth', ['user', 'empresaAtual', 'perfilAtual']),
    
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('patio', ['getServicos']),
  },
  async created() {
    if (this.empresaAtual.gate) {
      await this.getServicos({
        proprietario: this.empresaAtual.public_id,
      })
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = possible_gates
    }
  },
}
</script>
