var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"sort-by":"servico,data","items":_vm.faturamento,"footer-props":{
        itemsPerPageAllText: ("Todos (" + (_vm.faturamento.length) + ")"),
        itemsPerPageOptions: [50, 100, 150, 200, -1],
      }},scopedSlots:_vm._u([{key:"item.valor",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor) || _vm.toReal(0))+" ")]}}],null,true)})],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerstotal,"items":_vm.relatorioTotalTable,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.valor_total",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor_total) || _vm.toReal(0))+" ")]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }