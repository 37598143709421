<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <servico-list-filtro @search="handleGetServico" />
      </v-col>
    </v-row>

    <v-card class="pa-2 mt-5">
      <v-tabs v-model="tab">
        <v-tab href="#listagem" @change="carregarConteudo('listagem')"
          >Serviços</v-tab
        >
        <v-tab href="#detalhado" @change="carregarConteudo('detalhado')"
          >Relatório Detalhado Serviços</v-tab
        >
        <v-tab href="#eixos" @change="carregarConteudo('eixos')"
          >Serviços por Eixo</v-tab
        >
        <v-tab href="#produtoEixos" @change="carregarConteudo('produtoEixos')"
          >Produto por Eixo</v-tab
        >

        <v-tabs-items v-model="tab" class="mt-2">
          <v-tab-item eager value="listagem">
            <v-row>
              <v-col cols="12" sm="12">
                <BaseCard heading="Serviços Realizados">
                  <listagem-relatorio-servico
                    ref="listagem"
                  ></listagem-relatorio-servico>
                  <relatorio-total ref="total"></relatorio-total>
                </BaseCard>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item eager value="detalhado">
            <v-row>
              <v-col cols="12" sm="12">
                <BaseCard heading="Resumo">
                  <relatorio-detalhado ref="detalhado"></relatorio-detalhado>
                </BaseCard>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item eager value="eixos">
            <v-row>
              <v-col cols="12" sm="12">
                <BaseCard heading="Relatório Eixos">
                  <listagem-relatorio-servico-eixo ref="eixos"></listagem-relatorio-servico-eixo>
                </BaseCard>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item eager value="produtoEixos">
            <v-row>
              <v-col cols="12" sm="12">
                <BaseCard heading="Produto por Eixo">
                  <relatorio-produto-eixo ref="produtoEixos"></relatorio-produto-eixo>
                </BaseCard>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import servicoListFiltro from '@/components/financeiro/servico/relatorio/ServicoListFiltro.vue'
import ListagemRelatorioServico from '@/components/financeiro/servico/relatorio/Listagem.vue'
import RelatorioDetalhado from '@/components/financeiro/servico/relatorio/RelatorioDetalhado.vue'
import RelatorioProdutoEixo from '@/components/financeiro/servico/relatorio/RelatorioProdutoEixo.vue'
import RelatorioTotal from '@/components/financeiro/servico/relatorio/RelatorioTotal.vue'
import ListagemRelatorioServicoEixo from '@/components/financeiro/servico/relatorioEixo/Listagem.vue'


import { toReal, parseDatetime } from '@/mixins/convertion.js'
export default {
  name: 'Servico',

  components: {
    ListagemRelatorioServico,
    servicoListFiltro,
    RelatorioDetalhado,
    RelatorioProdutoEixo,
    RelatorioTotal,
    ListagemRelatorioServicoEixo
  },
  mixins: [toReal, parseDatetime],
  data: function () {
    return {
      tab: 'listagem',
      page: {
        title: 'Relatório de Servico',
      },
      breadcrumbs: [
        {
          text: 'Financeiro',
          disabled: true,
        },
        {
          text: 'Serviço',
          disabled: true,
        },
        {
          text: 'Relatório',
          disabled: false,
        },
      ],
    }
  },
  methods: {
    async carregarConteudo(tab) {
      this.tab = tab
      await this.$refs[tab].getServico()

      if(this.tab == "listagem"){
       await this.$refs['total'].getServico()
      }
    },
    async handleGetServico() {
      await this.carregarConteudo(this.tab)
    },
  },
}
</script>
