import { mapActions, mapMutations } from 'vuex'

import { cpf } from 'cpf-cnpj-validator'

export const methods = {
  ...mapActions('fornecedores', ['getFornecedores']),
  ...mapActions('clientes', ['getClientes']),
  ...mapActions('transportadoras', ['getTransportadoras']),
  ...mapActions('contratos', ['getContratos']),
  ...mapActions('patio', ['getRegioes', 'getRepresentantes']),
  ...mapActions('motoristas', ['getMotoristas']),
  ...mapMutations('relatorioServico', ['UPDATE_PARAMS']),
  ...mapActions('formaPagamento', ['getFormasPagamento']),
  ...mapActions('gerencial', [
    'getColaboradores',
    'getColaboradoresDaEmpresa',
  ]),

  handleFixParams(fixedParams, [key, param]) {
    if (param.value !== null && param.value !== '' && param.value !== undefined) {
      const paramsWithCPF = ["representante", "motorista"]
      const paramsWithServico = ["servico"]
      const paramsWithFormaPagamento = ["forma_pgto"]
      const paramsWithPublicId = ["fornecedor_field"]
      const paramsWithId = ["produto"]
      const paramsSubProdutoWithId = ["sub_produto"]

      if (paramsWithCPF.includes(key)) fixedParams[key] = cpf.strip(param.value)
      else if (paramsWithServico.includes(key)) fixedParams[key] = this.params.servico.value.map(r => r.id).toString()
      else if (paramsWithFormaPagamento.includes(key)) fixedParams[key] = this.params.forma_pgto.value.map(r => r.public_id).toString()
      else if (paramsWithPublicId.includes(key)) fixedParams[key] = this.params.fornecedor_field.value.fornecedor_id
      else if (paramsWithId.includes(key)) fixedParams[key] = this.params.produto.value.id
      else if (paramsSubProdutoWithId.includes(key)) fixedParams[key] = this.params.sub_produto.value.id
      else fixedParams[key] = param.value
    }

    return fixedParams
  },
  fixParams() {
    return Object.entries(this.params).reduce(this.handleFixParams, new Object())
  },

  addParamsFilter() {
    localStorage.setItem("filterOptionsDetailsServico", JSON.stringify(this.params))
  },

  getParamsFilter() {
    if (localStorage.getItem("filterOptionsDetailsServico")) {
      this.params = JSON.parse(localStorage.getItem("filterOptionsDetailsServico"))
    }
  },

  search() {
    if (!this.areSettingsEmpty) {
      const fixedParams = { ...this.fixParams() }
      this.UPDATE_PARAMS(fixedParams)
      this.addParamsFilter()
      this.$emit("search")
    }
  },

  async showOnlySelectedOptionsAndGetItems() {
    if (localStorage.getItem("filterOptionsDetailsServico")) {
      this.params = JSON.parse(localStorage.getItem("filterOptionsDetailsServico"))
    }
    this.allOptions.forEach(someOption => {
      if (this.params[someOption]) {
        this.params[someOption].visible = false
      }
    })
    this.selectedOptions.forEach(option => {
      this.params[option].visible = true
      //this.params[option].visible = true
    })
    this.allOptions.forEach(someOption => {
      if (this.params[someOption]?.visible == false) {
        this.params[someOption].value = ''
      }
    })
    this.addParamsFilter()
    this.showDaddy()
  },

  showDaddy() {
    for (let [daddy, dependents] of Object.entries(this.dependents)) {
      const isSomeDependentVisible = dependents.some(dependent => this.params[dependent].visible)
      if (isSomeDependentVisible) {
        this.params[daddy].visible = true
      }
    }
  }
}
