<template>
  <div>
    <v-col>
      <v-autocomplete
        :label="label"
        :prepend-icon="icon"
        :items="selectProdutos"
        item-text="descricao"
        return-object
        @click="selectAllProducts"
        @input="$emit('input', $event)"
      ></v-autocomplete>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
  name: 'ServicoListFiltroModalItemInputProdutos',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    fornecedor: {
      required: true,
    },
  },
  data() {
    return {
      selectProdutos: [],
    }
  },
  computed: {
    ...mapState('produtos', ['produtos']),
    ...mapState('auth', ['user', 'empresaAtual', 'perfilAtual']),
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapMutations('produtos', ['setProduto']),
    async selectAllProducts() {
      if (this.fornecedor.public_id != null) {
        this.setProduto(this.fornecedor.produtos)
        this.selectProdutos = [
          ...this.produtos,
        ]
      } else {
        this.selectProduto = [{ descricao: 'Nenhum', id: null }]
      }
    },
  },
  async created() {},
}
</script>
