<template>
  <v-col>
    <v-autocomplete
      v-model="value"
      :items="transportadorasSelecionadas"
      :search-input.sync="search"
      :label="label"
      :prepend-icon="icon"
      no-filter
      item-text="trade_name"
      item-value="transportadora_id"
      @input="$emit('input', $event)"
    >
    <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.trade_name"></v-list-item-title>
          <v-list-item-subtitle v-text="`CNPJ ${item.cnpj} - ${item.city} - ${item.district}`"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TransportadorasApi from '@/services/tenants/transportadoras.js'

export default {
  name: 'ServicoListFiltroModalItemInputTransportadoras',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    mask: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      required: true,
    },
  },
  data: () => ({
    search: '',
    transportadora: '',
    transportadorasSelecionadas: [],
    params: {
      inicias: {
        value: '',
      },
    },
  }),
  methods: {
    ...mapActions('transportadoras', [
      'getTransportadoras',
      'getTransportadoraEspecifica',
    ]),
    ...mapActions('template', ['successMessage']),

    async getTransportadoraSearch(parametros) {
      if (!Object.keys(parametros).length) return


      try {
        const transportadora = await TransportadorasApi.getTransportadoraEspecifica(
          this.empresaAtual,
          parametros
        )

        this.transportadorasSelecionadas = transportadora
   
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },
  },
  computed: {
    ...mapState('transportadoras', ['params']),
    ...mapState('auth', ['empresaAtual', 'user']),
  },
  async created() {},
  watch: {
    async search(search) {
      if (search.length > 2 && search.length <= 3) {
        await this.getTransportadoraSearch({
          pesquisa: search,
        })
      } else if (search.length < 2) {
        this.transportadorasSelecionadas = []
      }
    },
  },
}
</script>