<template>
  <div>
    <div class="mt-4">
      <v-card-title>
        Filtro
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-overlay v-model="carregandoDados">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-data-table
        :headers="headers"
        :search="search"
        sort-by="servico,data"
        :items="faturamento"
        :footer-props="{
          itemsPerPageAllText: `Todos (${faturamento.length})`,
          itemsPerPageOptions: [50, 100, 150, 200, -1],
        }"
      >
        <template v-slot:[`item.valor`]="{ item }">
          {{ toReal(item.valor) || toReal(0) }}
        </template>
        <template v-slot:[`item.peso`]="{ item }">
          {{ parseFloat(item.peso).toFixed(0) }}
        </template>
        <template v-slot:[`item.data`]="{ item }">
          {{ new Date(item.data).toLocaleString('pt-BR') }}
        </template>
        <template v-slot:[`item.cancelado_em`]="{ item }">
          {{
            item.cancelado_em == null
              ? ' '
              : new Date(item.cancelado_em).toLocaleString('pt-BR')
          }}
        </template>
      </v-data-table>
      <download-excel
        name="servicos.xls"
        :data="faturamento"
        :fields="fields_excel"
      >
        <v-btn color="info" class="w-100 mt-5">
          <v-icon class="mr-2" left dark> mdi-download </v-icon>
          Baixar Planilha</v-btn
        >
      </download-excel>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { toReal } from '@/mixins/convertion.js'

export default {
  name: 'ListagemRelatorioServico',

  mixins: [toReal],

  data() {
    return {
      fields_excel: {
        Senha: 'senha',
        Data: {
          callback: (value) => {
            let dataServico = new Date(value.data).toLocaleString("pt-br")
            return `${dataServico}`
          },
        },
        Serviço: 'servico',
        Valor: {
          callback: (value) => {
              return `${this.numberToReal(value.valor)}`
          },
        },
        'Forma de pagamento': 'forma_pagamento.descricao',
        Peso: {
          callback: (value) => {
              return `${parseFloat(value.peso).toFixed(0)}`
          },
        },
        Placa: 'placa_cavalo',
        Motorista: 'motorista',
        Transportador: 'transportadora.business_name',
        Terminal: 'terminal',
      },
      faturamento: [],
      relatorio: [],
      totalServicos: [],
      total: 0,
      search: '',
      dialog: null,
      headers: [
        { text: 'Senha', value: 'senha' },
        { text: 'Serviço', value: 'servico' },
        { text: 'Colaborador', value: 'colaborador' },
        { text: 'Peso', value: 'peso' },
        { text: 'Valor', value: 'valor' },
        { text: 'Forma de pagamento', value: 'forma_pagamento.descricao' },
        { text: 'Data', value: 'data' },
        { text: 'Placa', value: 'placa_cavalo' },
        { text: 'Motorista', value: 'motorista' },
        {
          text: 'Transportador',
          value: 'transportadora.business_name',
        },
        {
          text: 'Cliente',
          value: 'cliente',
        },
        {
          text: 'Fornecedor',
          value: 'fornecedor',
        },
        {
          text: 'Terminal',
          value: 'terminal',
        },
        { text: 'Status', value: 'status' },
        { text: 'Cancelado por', value: 'cancelado_por' },
        { text: 'Motivo', value: 'motivo' },
        { text: 'Data cancelamento', value: 'cancelado_em' },
      ],
      carregandoDados: false,
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('relatorioServico', ['params']),
  },
  methods: {
    ...mapActions('patio', [
      'getAllAgendamentosByParams',
      'getRelatorioServico',
      'getRegioesColaborator',
      'getRelatorioServicoDetalhado',
    ]),
    ...mapActions('template', ['errorMessage']),
    carregarDados() {
      this.carregandoDados = true
    },
    finalizarCarregamentoDados() {
      this.carregandoDados = false
    },
    numberToReal (numero) {
      numero = Number(numero).toFixed(2).split('.')
      numero[0] = 'R$ ' + numero[0].split(/(?=(?:...)*$)/).join('.')
      return numero.join(',')
    },
    async getServico() {
      if (!Object.keys(this.params).length) return
      this.carregarDados()
      if (
        this.empresaAtual.representantes.length > 0 &&
        this.params.regiao == undefined
      ) {
        await this.getRegioesColaborator()
        if (this.regioes.length > 0) {
          this.params.regiao = this.regioes[0].sigla
        }
      }

      try {
        const results = await this.getRelatorioServico({
          ...this.params,
        })
        this.faturamento = results.servicos
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
      this.finalizarCarregamentoDados()
    },
  },

  async created() {},
}
</script>
