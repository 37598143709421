<template>
  <v-col>
     <validation-provider v-slot="{ errors }" :name="name">
      <v-checkbox
        :value="inputValue"
        :label="label"
        :prepend-icon="icon"
        :error-messages="errors"
        v-model="model"
      />
    </validation-provider>
  </v-col>
</template>

<script>
export default {
  name: 'ServicoListFiltroModalItemInputStatus',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      required: true,
    },
    inputValue: {
      type: String
    },
  },
  data() {
    return {
      internalValue: '',
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
}
</script>
